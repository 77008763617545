import { useParams } from "react-router-dom";
import { BlogData } from "../utils/BlogData";

const useBlogFind = () => {
  const { id } = useParams();
  const blog = BlogData.find((blog) => blog.id === id);
  let blogFormatInfo = "";
  if (blog && blog.info) {
    blogFormatInfo = blog.info
      .replace(/\n\n/g, "<br /><br />")
      .replace(/\n/g, "<br />");
  }
  return { blog, blogFormatInfo };
};

export default useBlogFind;

// Import React and ReactDOM
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
import store from './store.js';


// Import the main App component
import App from './App';

// Import the CSS file
import './index.css';
// import store from './store';

// Create a root and render the App component into the root element
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
  <BrowserRouter>
  {/* <PersistGate loading={null} persistor={persistor}> */}

    <App />
    {/* </PersistGate> */}
	</BrowserRouter>
  </Provider>
  </React.StrictMode>
);

import {useState} from 'react'

const useToggle = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAnswer = () => {
      setIsOpen(!isOpen);
    };
     return {isOpen, toggleAnswer};
}

export default useToggle
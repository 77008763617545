import {useState} from 'react'

const useDisplayProduct = () => {
    const [displayProductCount, setDisplayProductCount] = useState(3);

    const handleProductViewMore = () => {
      setDisplayProductCount(displayProductCount + 3);
    };
  return {displayProductCount, handleProductViewMore};
}

export default useDisplayProduct
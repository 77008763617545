import React from 'react';
import { LiaTimesSolid } from "react-icons/lia";
import avatar from '../assets/images/avatar.png'
import { TbPhoneCall } from "react-icons/tb";


const OrderModal = ({ isOpen, onClose, order }) => {
  if (!isOpen || !order) return null;

  return (
    // <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    //   <div className="bg-white p-6 rounded-lg w-96 relative">
    //     <h2 className="text-xl font-semibold mb-4">Order Details</h2>
    //     <p><strong>Order Number:</strong> {order.orderno}</p>
    //     <p><strong>Merchant:</strong> {order.merchant}</p>
    //     <p><strong>Merchant Name:</strong> {order.merchantname}</p>
    //     <p><strong>Courier Name:</strong> {order.couriername}</p>
    //     <p><strong>Status:</strong> {order.status}</p>
    //     <button
    //       onClick={onClose}
    //       className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-full"
    //     >
    //       X
    //     </button>
    //   </div>
    // </div>
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white p-6 rounded-lg w-[40%]">
        <div className='flex justify-between items-center border-b'>

      <h2 className="text-xl py-1">Order: {order.orderno}</h2>
      <button onClick={onClose} className="text-right text-xl"><LiaTimesSolid /></button>
        </div>
        <div className='flex flex-col gap-4 '>
        <p className='bg-neutral text-center w-[30%] rounded-lg mt-4 py-1 text-basegreen'>{order.status}</p>
      
      <div>
        <div className='p-2 my-2'>
            <p className='text-[14px] text-basegreen'>Pick Up</p>
            <div className='flex mt-4 items-center justify-between'>
                <div className='flex w-[60%] gap-2 items-center '>

                <img src={avatar} alt="avatar" className='rounded-full w-[15%] aspect-square border p-2' />
                <div>{order.merchant}<br /><p className='text-[12px] text-primary/50'>5, Abdullahi str</p></div>
                </div>
                <div className='w-[40%] p-2 justify-center text-basegreen border-2 border-basegreen rounded-lg flex items-center gap-2'>
                    <TbPhoneCall /> 
                    <p>Call Merchant</p>
                    </div>
            </div>
        </div>
        <div className='p-2 my-2'>
            <p className='text-[14px] text-basegreen'>Pick Up</p>
            <div className='flex mt-4 items-center justify-between'>
                <div className='flex w-[60%] gap-2 items-center '>

                <img src={avatar} alt="avatar" className='rounded-full w-[15%] aspect-square border p-2' />
                <div>{order.merchantname}<br /><p className='text-[12px] text-primary/50'>5, Abdullahi str</p></div>
                </div>
                <div className='w-[40%] p-2 justify-center text-basegreen border-2 border-basegreen rounded-lg flex items-center gap-2'>
                    <TbPhoneCall /> 
                    <p>Call Customer</p>
                    </div>
            </div>
        </div>
        <div className='p-2 my-2'>
            <p className='text-[14px] text-basegreen'>Pick Up</p>
            <div className='flex mt-4 items-center justify-between'>
                <div className='flex w-[60%] gap-2 items-center '>

                <img src={avatar} alt="avatar" className='rounded-full w-[15%] aspect-square border p-2' />
                <div>{order.couriername}<br /><p className='text-[12px] text-primary/50'>5, Abdullahi str</p></div>
                </div>
                <div className='w-[40%] p-2 justify-center text-basegreen border-2 border-basegreen rounded-lg flex items-center gap-2'>
                    <TbPhoneCall /> 
                    <p>Call Courier</p>
                    </div>
            </div>
        </div>
        </div>
        <div>
            <p>Items</p>
            <ul>
                <li className='border-b text-primary/50 p-2'>Nike Air 270</li>
                <li className='border-b text-primary/50 p-2'>Addidas Yeezy Boost</li>
                <li className='border-b text-primary/50 p-2'>Nike Air 270</li>
            </ul>
        </div>
        </div>

    </div>
  </div>
  );
};

export default OrderModal;

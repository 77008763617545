// import React, { useState } from "react";
// import { LiaTimesSolid } from "react-icons/lia";
// import { useDispatch, useSelector } from "react-redux";

// import { fetchEvents, deleteEvent } from '../slice';
// import { registerEvent, sliceAction } from '../slice';
// import { FaSpinner } from "react-icons/fa"

// const Card = () => {
//   const dispatch = useDispatch()
//   const [showModal, setShowModal] = useState(false);
//   const [selectedEvent, setSelectedEvent] = useState(null);
//   const [formData, setFormData] = useState({
//     fullName: '',
//     email: ''
//   });
//   const AllEvents = useSelector(state => state.slice.AllEvents?.data)
//   const currentDate = new Date()
//   const upcomingEvents = AllEvents?.filter(event => new Date(event.date) >= currentDate);
//   const loading = useSelector(state => state.slice.fetchEventStatus)

//   console.log()

//   const openModal = (event) => {
//     setSelectedEvent(event);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedEvent(null);
//     setFormData({
//       fullName: '',
//       email: ''
//     });

//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const eventData = {
//       ...formData,
//       date: selectedEvent.date,
//       time: selectedEvent.time
//     };
//     dispatch(registerEvent(eventData)).then
//     (() =>
//     setFormData({
//       fullName: "",
//       email: ""
//     }),
//   setShowModal(false))
//   .catch((error) => {
//     // Handle any errors that occur during the dispatch
//     console.error("Error creating event:", error);
//   });
//   }

//   return (
//     <div className="grid grid-cols-3 gap-4 md:flex-row flex-col relative">
//       {upcomingEvents?.map((event, index) => (
//         <>
//           <div data-aos="zoom-out" key={index}>
//             <div
//               key={index}
//               className="flex flex-col gap-12  bg-basegreen py-8 px-6 mt-8 md:w-[25vw] aspect-square rounded-[12px] text-grey cursor-pointer"
//               onClick={() => openModal(event)}
//             >
//               <div className=" border rounded-[32px] w-fit px-4 py-2">
//                 <p className="text-textgreen text-[10px] font-normal">
//                   {event.date}
//                 </p>
//               </div>
//               <p className=" text-[26px] font-normal leading-8 ">
//                 {event.name}
//               </p>
//               <p className="text-[10px] tracking-wide font-light">
//                 RideFraser Spaces
//               </p>
//             </div>
//             <p className="text-secondary text-[14px] mt-4 tracking-wide font-normal">
//               {event.date}
//             </p>
//             <p className="text-[16px] text-primary font-normal mt-2">
//               {event.name}
//             </p>
//           </div>
//         </>
//       ))}
//       {showModal && (
//         <div className=" fixed bg-primary/50 backdrop-blur-sm flex items-center place-content-center w-[100vw] top-0 left-0 right-0 h-[100vh] z-50 overflow-hidden ">
//           <div className="modal bg-grey md:w-[35vw] w-[80vw] relative rounded-lg h-[90vh] overflow-y-auto">
//             <div className="flex justify-between border-b items-center p-4">
//               <h3 className="font-normal text-[21px]">Register for Event</h3>
//               <div className="" onClick={closeModal}>
//               <LiaTimesSolid size={20}/>
//               </div>
//             </div>

//             <div className=" p-4">
//               <p className="font-light text-[16px] text-secondary ">
//                 RideFraser Spaces
//               </p>
//               <div className=" bg-basegreen py-8 px-6 mt-4 rounded-[12px] text-grey">
//                 <h3 className="md:text-[32px] text-[20px] font-normal ">
//                   {selectedEvent.name}
//                 </h3>
//               </div>
//               <div className="flex gap-24 font-normal mt-4">
//                 <div>
//                   <p className="text-[16px] text-secondary">Date</p>
//                   <p className="text-[14px] md:text-[16px]">{selectedEvent.date}</p>
//                 </div>
//                 <div>
//                   <p className="text-[16px] text-secondary">Time</p>
//                   <p className="text-[14px] md:text-[16px]">{selectedEvent.time}</p>
//                 </div>
//               </div>
//               <form className="py-10" onSubmit={handleSubmit}>
//                 <p className="text-secondary text-[16px] font-normal mb-2">Attendee details</p>
//                 <div className="flex flex-col gap-4">
//                   <input type="text" name="fullName" placeholder="Full name" id="" className="border-2 rounded-lg h-[50px] py-2 px-4 font-normal focus:border-basegreen outline-none focus:ring-basegreen focus:ring-opacity-50" required value={formData.fullName} onChange={handleChange} />
//                   <input type="email" name="email" placeholder="Email address" id="" className="border-2 rounded-lg h-[50px] py-2 px-4 font-normal focus:border-basegreen outline-none focus:ring-basegreen focus:ring-opacity-50" required value={formData.email} onChange={handleChange}/>
//                 </div>
//                 <button type="submit" className=" text-white text-[16px] leading-[24px] font-normal w-full h-[56px] bg-basegreen rounded-full cursor-pointer mt-4">Register</button>
//                 </form>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Card;

import React, { useEffect, useState } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents, registerEvent } from "../slice";
import { FaSpinner } from "react-icons/fa";

const Card = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
  });

  const AllEvents = useSelector((state) => state.slice.AllEvents?.data);
  const eventStatus = useSelector((state) => state.slice.fetchEventStatus);
  const eventStatus2 = useSelector((state) => state.slice.registerEventStatus);
  const currentDate = new Date();
  const upcomingEvents = AllEvents?.filter(
    (event) => new Date(event.date) >= currentDate
  );
  console.log(eventStatus);

  useEffect(() => {
    if (eventStatus === "idle") {
      dispatch(fetchEvents());
    }
  }, [eventStatus, dispatch]);

  const openModal = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setFormData({
      fullName: "",
      email: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const eventData = {
      id: selectedEvent._id,
      ...formData,
      date: selectedEvent.date,
      time: selectedEvent.time,
    };
    dispatch(registerEvent(eventData))
      .then(() => {
        setFormData({
          fullName: "",
          email: "",
        });
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error creating event:", error);
      });
  };

  return (
    <div>
      {eventStatus === "loading" && (
        <div className="flex justify-center items-center w-full h-full">
          <FaSpinner className="animate-spin text-4xl text-basegreen" />
        </div>
      )}
      <div className="grid grid-cols-3 gap-4 md:flex-row flex-col relative">
        {eventStatus === "success" &&
          upcomingEvents?.map((event, index) => (
            <div data-aos="zoom-out" key={index}>
              <div
                className="flex flex-col gap-12 bg-basegreen py-8 px-6 mt-8 md:w-[25vw] aspect-square rounded-[12px] text-grey cursor-pointer"
                onClick={() => openModal(event)}
              >
                <div className="border rounded-[32px] w-fit px-4 py-2">
                  <p className="text-textgreen text-[10px] font-normal">
                    {event.date}
                  </p>
                </div>
                <p className="text-[26px] font-normal leading-8">
                  {event.name}
                </p>
                <p className="text-[10px] tracking-wide font-light">
                  RideFraser Spaces
                </p>
              </div>
              <p className="text-secondary text-[14px] mt-4 tracking-wide font-normal">
                {event.date}
              </p>
              <p className="text-[16px] text-primary font-normal mt-2">
                {event.name}
              </p>
            </div>
          ))}
        {showModal && (
          <div className="fixed bg-primary/50 backdrop-blur-sm flex items-center place-content-center w-[100vw] top-0 left-0 right-0 h-[100vh] z-50 overflow-hidden">
            <div className="modal bg-grey md:w-[35vw] w-[80vw] relative rounded-lg h-[90vh] overflow-y-auto">
              <div className="flex justify-between border-b items-center p-4">
                <h3 className="font-normal text-[21px]">Register for Event</h3>
                <div onClick={closeModal}>
                  <LiaTimesSolid size={20} />
                </div>
              </div>
              <div className="p-4">
                <p className="font-light text-[16px] text-secondary">
                  RideFraser Spaces
                </p>
                <div className="bg-basegreen py-8 px-6 mt-4 rounded-[12px] text-grey">
                  <h3 className="md:text-[32px] text-[20px] font-normal">
                    {selectedEvent.name}
                  </h3>
                </div>
                <div className="flex gap-24 font-normal mt-4">
                  <div>
                    <p className="text-[16px] text-secondary">Date</p>
                    <p className="text-[14px] md:text-[16px]">
                      {selectedEvent.date}
                    </p>
                  </div>
                  <div>
                    <p className="text-[16px] text-secondary">Time</p>
                    <p className="text-[14px] md:text-[16px]">
                      {selectedEvent.time}
                    </p>
                  </div>
                </div>
                <form className="py-10" onSubmit={handleSubmit}>
                  <p className="text-secondary text-[16px] font-normal mb-2">
                    Attendee details
                  </p>
                  <div className="flex flex-col gap-4">
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Full name"
                      className="border-2 rounded-lg h-[50px] py-2 px-4 font-normal focus:border-basegreen outline-none focus:ring-basegreen focus:ring-opacity-50"
                      required
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      className="border-2 rounded-lg h-[50px] py-2 px-4 font-normal focus:border-basegreen outline-none focus:ring-basegreen focus:ring-opacity-50"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="text-white text-[16px] leading-[24px] font-normal w-full h-[56px] bg-basegreen rounded-full cursor-pointer mt-4"
                  >
                    {eventStatus2 === "loading" ? (
                      <div className="flex justify-center items-center w-full h-full">
                        <FaSpinner className="animate-spin text-4xl text-white" />
                      </div>
                    ) : (
                      "Register"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import Globe from '../assets/images/globe.svg'

const NavbarModal = ({handleLinkClick}) => {
  const location = useLocation();
  const isActive = (path) => location.pathname.startsWith(path);
 
  
  return (
    <>
      <div className="fixed md:hidden z-[100] left-0 top-[64px] w-[100vw] h-[100vh] animate-scale-up-right">
        <div className=" bg-white flex flex-col justify-between w-[100%] h-[90vh] px-[20px] py-[50px] ">
          <ul className="w-full list-none gap-5 flex flex-col items-start cursor-pointer">
            <li className="relative overflow-hidden inline-block py-[10px] cursor-pointer text-[16px] font-[400] leading-[18px] -tracking-[3%]">
              <Link
                to="/about"
                className={
                  isActive("/about")
                    ? "text-basegreen"
                    : "text-secondary  hover:text-basegreen"
                }
                onClick={handleLinkClick}
              >
                About
              </Link>
            </li>
            <li className="relative overflow-hidden inline-block py-[10px] cursor-pointer text-[16px] font-[400] leading-[18px] -tracking-[3%]">
              <Link
                to="/blog"
                className={
                  isActive("/blog")
                    ? "text-basegreen"
                    : "text-secondary  hover:text-basegreen"
                }
                onClick={handleLinkClick}
              >
                Blog
              </Link>
            </li>

            <li className="relative overflow-hidden inline-block py-[10px] cursor-pointer text-[16px] font-[400] leading-[18px] -tracking-[3%] text-secondary  hover:text-basegreen" onClick={handleLinkClick}>
              <a href="https://fraser.stoplight.io/docs/central/cbb186dc155a8-merchant-management" target="_blank" rel="noreferrer" >
                API Documentation
              </a>
            </li>
          </ul>
          <div className="flex flex-col ">
            <Link to="/community" onClick={handleLinkClick}>
              <div className="flex items-center gap-2 ">
                <img
                  src={Globe}
                  alt="Globe icon"
                  className="w-[24px] h-[24px]"
                />

                <button className="cursor-pointer md:text-[14px] xl:text-[16px] font-[400] leading-[18px] text-primary -tracking-[3%]">
                  Join our community
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarModal;

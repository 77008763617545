import React from 'react'
import Hero from '../components/Hero'
import Information from '../components/Information'

const Community = () => {
  return (
    <div className='max-w-[100%]'>
    < Hero />
    < Information />
    </div>
  )
}

export default Community
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Community from "./pages/Community";
import Blog from "./pages/Blog";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import BlogDetail from "./components/BlogDetail";
import Admin from "./pages/Admin";
import Dashboard from "./components/Dashboard";
import Events from './components/Events'
import Sidebar from "./components/Sidebar";
import { useDispatch } from "react-redux";
import { fetchEvents, sliceAction } from "./slice";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
  // Animate on Scroll
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchEvents())
  }, [null]
  )

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: true, 
    });
   
  }, []);
  return (
    <>
      
      {location.pathname !== "/dashboard" && location.pathname !== "/events" && <Navbar />}
      {/* {(location.pathname === "/dashboard" || location.pathname === "/events") && <Sidebar />} */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/community" element={<Community />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/admin" element={<Admin />} /> */}
        <Route path="/events" element={<Events />} />

      </Routes>
      <Footer/>
    </>
  );
};

export default App;

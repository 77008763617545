import Sidebar  from './Sidebar'
import React, {useState} from 'react'
import OrderModal from './OrderModal';

const data = [
  {
    name: 'Total Merchants',
    num: 40
  },
  {
    name: 'Total Orders',
    num: 40
  },
  {
    name: 'Orders Delivered',
    num: 40
  },
  {
    name: 'Transaction Volume',
    num: 40
  },
  {
    name: 'Total Couriers',
    num: 40
  },
]
const orders = [
  {
    id: 1,
    orderno: 'Order 10010',
    merchant: 'Soft Merchant',
    merchantname: 'Amen Olabode',
    couriername: 'Kabiru Moshood',
    status: 'Order Picked Up',
    link: 'view'
  },
  {
    id: 2,
    orderno: 'Order 10010',
    merchant: 'Soft Merchant',
    merchantname: 'Amen Ola',
    couriername: 'Kabiru Moshood',
    status: 'Order Picked Up',
    link: 'view'
  },
  {
    id: 3,
    orderno: 'Order 10010',
    merchant: 'Soft Merchant',
    merchantname: 'Amen Olabode',
    couriername: 'Kabiru Moshood',
    status: 'Order Picked Up',
    link: 'view'
  },
]

const Dashboard = () => {

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedOrder(null);
    setIsModalOpen(false);
  };

  return (
    <div className='flex w-full'>
        <Sidebar />
        <div className='w-full'>
        <div className='flex justify-between w-full px-8 py-10 border-b h-[88px] items-center '>
          <h1 className='font-semibold text-[24px] text-primary tracking-wide'>Dashboard</h1>
          <button>hhjjj</button>
        </div>
        <div className='flex justify-around mt-10'>
          {data.map((item, index) => (
            <div className='h-[100px] w-[180px] bg-basegreen flex flex-col justify-center rounded-lg pl-6 pr-2'> 
              <p className='text-[12px] text-neutral'>{item.name}</p>
              <p className='text-[30px] text-neutral'>{item.num}</p>
            </div>
          )
          )}
        </div>
        <div className='mt-4 px-4'>
          <p className='text-basegreen'>Active Orders</p>
          <div className='flex flex-col gap-4 mt-10 '>
          {orders.map((order, id) => (
          <div className='grid grid-cols-6 justify-center items-center text-primary hover:text-secondary hover:bg-primary/10 py-2 pl-4' key={id}>
            <p>{order.orderno}</p>
            <p>{order.merchant}</p>
            <p>{order.merchantname}</p>
            <p>{order.couriername}</p>
            <p>{order.status}</p>
            {/* <a href='#'>{order.link}</a> */}
            <button onClick={() => openModal(order)} className='text-link'>View</button>

          </div>
          ) )
          }
          </div>
        </div>
        
        </div>
        <OrderModal isOpen={isModalOpen} onClose={closeModal} order={selectedOrder} />
        </div>
  )
}

export default Dashboard
import  { useState } from 'react';
import { useInView } from "react-intersection-observer";

 // Lazy Load for Map Image
const useBgLoaded = () => {
    const [bgLoaded, setBgLoaded] = useState(false);

    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    return {ref, inView, bgLoaded, setBgLoaded};
}

export default useBgLoaded
 export const FAQData = [
    {
      id: 1,
      question: "What is RideFraser?",
      answer: "RideFraser is an all-in-one e-commerce logistics platform designed to simplify shipping for Nigerian businesses. We handle everything from finding the best courier to tracking your packages and managing returns, so you can focus on growing your business.",
    },
    {
      id: 2,
      question: "How does RideFraser work?",
      answer: " It's easy! Connect your online store or social media shop to RideFraser, set your delivery preferences, and we'll automate the rest. We'll find the right courier, track your shipments in real-time, and handle any returns seamlessly.",
    },
    {
      id: 3,
      question: "What types of businesses can use RideFraser?",
      answer:
        "RideFraser is perfect for businesses of all sizes, from small online shops to established e-commerce stores. Whether you're selling on social media, your own website, or a marketplace, we can help you streamline your logistics.",
    },
    {
      id: 4,
      question: " How much does RideFraser cost?",
      answer:
        "We offer flexible pricing plans to suit your business needs. You can choose from pay-as-you-go options or monthly subscriptions. We also offer special rates for high-volume shippers.",
    },
    {
      id: 5,
      question: " How do I track my deliveries?",
      answer:
        " You can easily track your deliveries in real-time through our platform. We'll also keep your customers updated with tracking information, so they know when to expect their orders.",
    },
    {
      id: 6,
      question: "What happens if my package gets lost or damaged?",
      answer:
        "We offer insurance options for added peace of mind. If your package gets lost or damaged, we'll work with the courier to resolve the issue and ensure you receive compensation.",
    },
    {
      id: 7,
      question: "What if my customer wants to return an order?",
      answer:
        "Our platform simplifies the return process for both you and your customers. We'll provide a return label and handle the logistics, so you can focus on providing excellent customer service.",
    },
    {
      id: 8,
      question: "How can I get started with RideFraser?",
      answer:
        " Getting started is easy! Simply sign up on our website and follow the instructions to connect your store. Our team is also available to answer any questions and help you get set up.",
    },
    {
      id: 9,
      question: "Do you offer customer support?",
      answer:
        "Absolutely! Our dedicated customer support team is available to assist you with any questions or issues you may have. You can reach us via email, phone, or chat.",
    },
    {
      id: 10,
      question: "Is my data secure with RideFraser?",
      answer:
        " Yes, we take data security very seriously. We use industry-standard encryption and security measures to protect your information.",
    },
    {
      id: 11,
      question: "Where does RideFraser operate?",
      answer:
        "Currently, we operate within Nigeria. However, we have plans to expand to other African countries in the future.",
    },
  ];
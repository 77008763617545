

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import EventModal from './EventModal';
import { fetchEvents, deleteEvent } from '../slice';
import { LiaTimesSolid } from "react-icons/lia";
import { createEvent, sliceAction } from '../slice';
import { BsArrowBarUp } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa"


const Events = ({onClose}
) => {
  const dispatch = useDispatch();
  
  // Fetch events when the component mounts
  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  
  const [imageURL, setImageURL] = useState(null);
  const [image, setimage] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [previousEvent, setPreviousEvent] = useState([]);
  const [eventEdit, setEventEdit] = useState({})
  const [event, setEvent] = useState({
    title: "",
    day: "",
    time: "",
    guest: "",
    date: "",
    image: null
  });
  const loading = useSelector((state) => state.slice.deleteEventStatus);


const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  };
  
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const newImageURL = URL.createObjectURL(file);
      setImageURL(newImageURL);
      console.log(newImageURL)
      setimage(file)
      
      return () => URL.revokeObjectURL(newImageURL);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', event.title);
    formData.append('date', event.date);
    formData.append('time', event.time);
    formData.append('description', event.title);

    if (image) {
      formData.append('images', image);
    }
    console.log(formData)
    // Dispatch createEvent action
    dispatch(createEvent(formData)).then(() => {
      setEvent({
        title: "",
        day: "",
        time: "",
        guest: "",
        date: "",
        image: null
       })
      onClose()
      
     })
     .catch((error) => {
      // Handle any errors that occur during the dispatch
      console.error("Error creating event:", error);
    });
        
  
  }
  const { allEvents, status, error } = useSelector(state => ({
    allEvents: state.slice.AllEvents?.data || [],
    status: state.slice.fetchEventStatus,
    error: state.slice.error,
  }));
  console.log(allEvents)

  useEffect(() => {
    if (status === 'success') {
      const currentDate = new Date();
      const upcomingEvents = allEvents.filter(event => new Date(event.date) >= currentDate);
      const previousEvents = allEvents.filter(event => new Date(event.date) < currentDate);
      setUpcomingEvent(upcomingEvents);
      setPreviousEvent(previousEvents);
      console.log(upcomingEvents)
    }
  }, [allEvents, status]);
console.log(previousEvent)
  const handleCreateNewEvent = () => {
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    await dispatch(deleteEvent(id));
    dispatch(fetchEvents());
    closeModal()

    console.log('jj')
  };
  const handleEdit = async (data) => {
    setEventEdit(data)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveEvent = (newEvent) => {
    setIsModalOpen(false);
    console.log(newEvent);
  };

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const openModal = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };
  const openEditModal = (event) => {
    setShowEditModal(true)
    setSelectedEvent(event)
  }
  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedEvent(null);
  };

  return (
    <div className="flex w-full">
      <Sidebar />
      <div className='w-full'>
        <div className='flex justify-between w-full px-8 py-10 border-b h-[88px] items-center '>
          <h1 className='font-semibold text-[24px] text-primary tracking-wide'>Events</h1>
          <button onClick={handleCreateNewEvent} className='text-neutral text-[16px] rounded-full bg-basegreen px-6 py-3'>Create New Event</button>
        </div>
        <div className='flex justify-between items-center px-6 mt-10'>
          <p className='text-basegreen'>Upcoming Events</p>
        </div>

{upcomingEvent.name}
        <div className='mt-4'>
          {status === 'loading' && <p className='text-center'>Loading events...</p>}
          {status === 'failed' && <p className='text-center'>Error loading events: {error}</p>}
          {status === 'success' && (
            <>
              {upcomingEvent.length === 0 ? (
  <p className='text-center'>No Events Available.</p>
) : (
  <table className="min-w-full table-auto border-collapse">
    <tbody>
      {upcomingEvent?.map((event, index) => (
        <tr key={index} className="odd:bg-white even:bg-gray-100">
          <td className="px-4 py-2 w-[50%]">{event.name}</td>
          <td className="px-4 py-2 w-[20%]">{event.date}</td>
          <td className="px-4 py-2 w-[15%]">{event.time}</td>
          {/* <td className="px-4 py-2 w-[15%]">{event.guest}</td> */}
          <td className="px-4 py-2">
            <button className="text-basegreen hover:underline" onClick={() => openModal(event)}>View</button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)}

            </>
          )}
        </div>
        <div className="mt-5">
          <div className='flex justify-between items-center px-6 mt-10'>
            <p className='text-basegreen'>Previous Events</p>
          </div>
          <div className='mt-4'>
            {/* {status === 'success' && previousEvent.length === 0 && <p>No previous events.</p>} */}
            {status === 'success' && (
              <>
              {previousEvent.length === 0 ? (
  <p className='text-center'>No previous events.</p>
) : (
  <table className="min-w-full table-auto border-collapse">
    <tbody>
      {previousEvent?.map((event, index) => (
        <tr key={index} className="odd:bg-white even:bg-gray-100">
          <td className="px-4 py-2 w-[50%]">{event.name}</td>
          <td className="px-4 py-2 w-[20%]">{event.date}</td>
          <td className="px-4 py-2 w-[15%]">{event.time}</td>
          {/* <td className="px-4 py-2 w-[15%]">{event.guest}</td> */}
          <td className="px-4 py-2">
            <button className="text-basegreen hover:underline" onClick={() => openModal(event)}>View</button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)}

              </>
              
          
            )}
          </div>
        </div>
        <EventModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveEvent} />
      </div>
      {showModal && selectedEvent && (
        <div className="fixed bg-primary/50 backdrop-blur-sm flex items-center place-content-center w-[100vw] top-0 left-0 right-0 h-[100vh] z-50 overflow-hidden">
          <div className="modal bg-grey md:w-[35vw] w-[80vw] relative rounded-lg h-[90vh] overflow-y-auto">
            <div className="flex justify-between border-b items-center p-4">
              <h3 className="font-normal text-[21px]">Event Details</h3>
              <div className="" onClick={closeModal}>
                <LiaTimesSolid size={20} />
              </div>
            </div>
            <div className="p-4">
              <p className="font-light text-[16px] text-secondary ">
                {selectedEvent.description}
              </p>
              <div className="bg-basegreen py-8 px-6 mt-4 rounded-[12px] text-grey">
                <h3 className="md:text-[32px] text-[20px] font-normal ">
                  {selectedEvent.name}
                </h3>
              </div>
              <div className="flex gap-24 font-normal my-4">
                <div>
                  <p className="text-[16px] text-secondary">Date</p>
                  <p className="text-[14px] md:text-[16px]">{selectedEvent.date}</p>
                </div>
                <div>
                  <p className="text-[16px] text-secondary">Time</p>
                  <p className="text-[14px] md:text-[16px]">{selectedEvent.time}</p>
                </div>
              </div>
              <div>
                <p className="text-[16px] text-secondary">Attendees</p>
                <p className="text-[14px] md:text-[16px]">{selectedEvent.guest}</p>
              </div>
              <div className='flex flex-col gap-4 mt-12'>
                <button className='text-red' onClick={() => handleDelete(selectedEvent._id)}>
                {loading === 'loading' ? 
                <div className='flex gap-2 items-center justify-center'>
                  <p>
                  Deleting
                  </p>
                  <p>
                    <FaSpinner className="animate-spin
                    "/>
                  </p>

                </div>
                : "Delete"}

                </button>
                
              </div>
            </div>
          </div>
        </div>
      )}
      {showEditModal && eventEdit && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-[40%] max-h-[90vh] overflow-auto">
        <div className="flex justify-between items-center">
        <h2 className="text-xl py-1">Edit an event</h2>
        <button onClick={onClose} className=" text-xl">
          <LiaTimesSolid />
        </button>
        </div>
        <label htmlFor="cover-image-upload" className="cursor-pointer">
          <div className="px-12 py-16 flex flex-col items-center justify-center border-dashed border-2 rounded-lg my-4 text-primary/50">
            <BsArrowBarUp />
            <p>Upload Image</p>
          </div>
          <input
          name="image"
            id="cover-image-upload"
            type="file"
            className="hidden"
            onChange={handleFileChange}
          />
           {imageURL && <img src={imageURL} alt="Selected preview" />}
        </label>

        <form onSubmit={handleSubmit} className="my-8 flex flex-col gap-4">
          <div>
            <label className="block">Event Name</label>
            <input
              name="title"
              value={event.title}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label>Date</label>
              <input
                name="date"
                type="date"
                value={event.date}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
            <div>
              <label>Time</label>
              <input
                name="time"
                type="time"
                value={event.time}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>
          </div>
          <button  type="submit" className="bg-basegreen w-full text-white p-2 rounded-full">
            Edit Event
          </button>
        </form>
      </div>
      </div>
      )}
    </div>
  );
};

export default Events;

import React from 'react';

const PrivacyPolicy = () => {
	return (
		<div className='max-w-4xl mx-auto p-8'>
			<h1 className='text-3xl font-bold mb-6'>Privacy Policy</h1>

			<p className='text-gray-700 mb-4'>
				Ridefraser ("we", "our", "us") is committed to protecting and
				respecting your privacy. This Privacy Policy explains how we
				collect, use, disclose, and safeguard your information when you
				use our platform, including our website, mobile application, and
				services (collectively, the "Services"). Please read this policy
				carefully to understand our views and practices regarding your
				personal data and how we will treat it.
			</p>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				1. Information We Collect
			</h2>

			<h3 className='text-xl font-medium mt-6 mb-2'>
				1.1 Personal Information
			</h3>
			<p className='text-gray-700 mb-4'>
				When you sign up for our Services, we may collect the following
				personal information from you:
			</p>
			<ul className='list-disc list-inside text-gray-700 mb-4'>
				<li>
					<strong>Identity Data:</strong> Name, username, date of
					birth, gender, and other identifiers.
				</li>
				<li>
					<strong>Contact Data:</strong> Email address, phone number,
					physical address, and emergency contact information.
				</li>
				<li>
					<strong>Financial Data:</strong> Payment card details,
					billing address, and transaction information.
				</li>
				<li>
					<strong>Profile Data:</strong> Your username, password,
					orders, feedback, and survey responses.
				</li>
				<li>
					<strong>Usage Data:</strong> Information about how you use
					our website, app, and services.
				</li>
			</ul>

			<h3 className='text-xl font-medium mt-6 mb-2'>
				1.2 Non-Personal Information
			</h3>
			<p className='text-gray-700 mb-4'>
				We may collect non-personal information that does not directly
				identify you:
			</p>
			<ul className='list-disc list-inside text-gray-700 mb-4'>
				<li>
					<strong>Device Data:</strong> Information about your device
					including your IP address, operating system, and browser
					type.
				</li>
				<li>
					<strong>Log Data:</strong> Details of your visits to our
					site, including traffic data, location data, and other
					communication data.
				</li>
				<li>
					<strong>Location Data:</strong> GPS location data when you
					use location-based features of our Services.
				</li>
			</ul>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				2. How We Use Your Information
			</h2>
			<p className='text-gray-700 mb-4'>
				We may use your information for the following purposes:
			</p>
			<ul className='list-disc list-inside text-gray-700 mb-4'>
				<li>
					<strong>To Provide and Improve Services:</strong> To set up
					and manage your account, process payments, fulfill your
					orders, and enhance the performance of our Services.
				</li>
				<li>
					<strong>To Communicate with You:</strong> To send you
					account-related communications, respond to your inquiries,
					and provide customer support.
				</li>
				<li>
					<strong>To Personalize Your Experience:</strong> To tailor
					our content and services to your preferences.
				</li>
				<li>
					<strong>To Ensure Security:</strong> To protect against
					fraud, unauthorized transactions, and other illegal
					activities.
				</li>
				<li>
					<strong>To Comply with Legal Obligations:</strong> To meet
					our legal and regulatory requirements.
				</li>
				<li>
					<strong>For Marketing:</strong> To send you promotional
					materials, offers, and news about our Services that may
					interest you, with your consent where required.
				</li>
			</ul>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				3. Sharing Your Information
			</h2>
			<p className='text-gray-700 mb-4'>
				We may share your information with:
			</p>
			<ul className='list-disc list-inside text-gray-700 mb-4'>
				<li>
					<strong>Service Providers:</strong> Third parties who
					perform services on our behalf, such as payment processing,
					data analysis, email delivery, hosting, and customer
					service.
				</li>
				<li>
					<strong>Business Partners:</strong> Trusted partners with
					whom we collaborate to offer joint services or promotions.
				</li>
				<li>
					<strong>Law Enforcement:</strong> When required by law or
					necessary to comply with legal processes, protect our
					rights, or ensure the safety of our users.
				</li>
				<li>
					<strong>Corporate Transactions:</strong> In the event of a
					merger, acquisition, or sale of all or part of our business,
					your information may be transferred to the new entity.
				</li>
			</ul>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				4. Cookies and Tracking Technologies
			</h2>
			<p className='text-gray-700 mb-4'>
				We use cookies and similar tracking technologies to collect and
				store information about your interaction with our Services. This
				includes:
			</p>
			<ul className='list-disc list-inside text-gray-700 mb-4'>
				<li>
					<strong>Session Cookies:</strong> To manage your session on
					our platform.
				</li>
				<li>
					<strong>Persistent Cookies:</strong> To remember your
					preferences and settings.
				</li>
				<li>
					<strong>Analytics Cookies:</strong> To analyze site usage
					and improve performance.
				</li>
			</ul>
			<p className='text-gray-700 mb-4'>
				You can manage your cookie preferences through your browser
				settings. However, disabling cookies may affect your ability to
				use certain features of our Services.
			</p>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				5. Data Security
			</h2>
			<p className='text-gray-700 mb-4'>
				We implement appropriate technical and organizational measures
				to protect your personal data against unauthorized access, loss,
				or destruction. These measures include encryption, access
				controls, and secure data storage practices. However, no method
				of transmission over the internet or electronic storage is 100%
				secure, and we cannot guarantee absolute security.
			</p>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				6. Data Retention
			</h2>
			<p className='text-gray-700 mb-4'>
				We retain your personal data for as long as necessary to fulfill
				the purposes outlined in this Privacy Policy or as required by
				law. Once your information is no longer needed, we will securely
				delete or anonymize it.
			</p>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>7. Your Rights</h2>
			<p className='text-gray-700 mb-4'>
				You have certain rights regarding your personal data, including:
			</p>
			<ul className='list-disc list-inside text-gray-700 mb-4'>
				<li>
					<strong>Access:</strong> The right to request a copy of the
					personal data we hold about you.
				</li>
				<li>
					<strong>Correction:</strong> The right to request correction
					of any inaccurate or incomplete data.
				</li>
				<li>
					<strong>Deletion:</strong> The right to request the deletion
					of your personal data in certain circumstances.
				</li>
				<li>
					<strong>Objection:</strong> The right to object to our
					processing of your personal data for direct marketing
					purposes.
				</li>
				<li>
					<strong>Restriction:</strong> The right to request that we
					restrict the processing of your data in certain situations.
				</li>
				<li>
					<strong>Data Portability:</strong> The right to receive your
					personal data in a structured, commonly used, and
					machine-readable format.
				</li>
			</ul>
			<p className='text-gray-700 mb-4'>
				To exercise these rights, please contact us at
				contact@ridefraser.com.
			</p>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				8. Children's Privacy
			</h2>
			<p className='text-gray-700 mb-4'>
				Our Services are not directed to children under the age of 13,
				and we do not knowingly collect personal data from children
				under 13. If we become aware that we have inadvertently
				collected personal data from a child under 13, we will take
				steps to delete such information.
			</p>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				9. International Data Transfers
			</h2>
			<p className='text-gray-700 mb-4'>
				We may transfer your personal data to countries outside of your
				jurisdiction, including to countries that may not provide the
				same level of data protection as your own. When we do so, we
				will ensure that appropriate safeguards are in place to protect
				your data, such as standard contractual clauses or other legal
				mechanisms.
			</p>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>
				10. Changes to This Privacy Policy
			</h2>
			<p className='text-gray-700 mb-4'>
				We may update this Privacy Policy from time to time to reflect
				changes in our practices or for other operational, legal, or
				regulatory reasons. We will notify you of any significant
				changes by posting the new policy on our website and updating
				the "Effective Date" above. Your continued use of our Services
				after such changes constitutes your acceptance of the updated
				policy.
			</p>

			<h2 className='text-2xl font-semibold mt-8 mb-4'>11. Contact Us</h2>
			<p className='text-gray-700 mb-4'>
				If you have any questions or concerns about this Privacy Policy
				or our data practices, please contact us at:
			</p>
			<ul className='list-disc list-inside text-gray-700 mb-4'>
				<li>
					<strong>Email:</strong> contact@ridefraser.com
				</li>
			</ul>
		</div>
	);
};

export default PrivacyPolicy;

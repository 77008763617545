import React from "react";
import { BlogData, ProductData } from "../utils/BlogData";
import BlogBox from "../components/BlogBox";
import useScrollTop from "../custom-hooks/useScrollTop";
import useDisplayBlog from "../custom-hooks/useDisplayBlog";
import useDisplayProduct from "../custom-hooks/useDisplayProduct";

const Blog = () => {
  const { scrollToTop } = useScrollTop();
  const { displayBlogCount, handleBlogViewMore } = useDisplayBlog();
  const {displayProductCount, handleProductViewMore} = useDisplayProduct();
 

  return (
    <div className="mt-[90px] flex justify-center my-[40px] max-w-full">
      <div className="w-[370px] md:w-[650px] lg:w-[980px] h-auto flex flex-col py-[20px] px-[10px]">
        {/* Blog */}
        <h1 className="text-primary text-[24px] font-[400] leading-[30px] -tracking-[3%] px-[20px] xl:px-0 my-[10px]">
          Blog
        </h1>
        <div className="flex flex-col md:flex-row justify-center items-center flex-wrap gap-5">
          {BlogData.slice(0, displayBlogCount).map((blog) => (
            <BlogBox
              key={blog.id}
              id={blog.id}
              title={blog.title}
              image={blog.image}
              date={blog.date}
              info={blog.info}
              scrollToTop={scrollToTop}
            />
          ))}
        </div>
        {displayBlogCount < BlogData.length && (
          <button
            className="text-basegreen text-center underline underline-offset-4 text-[16px] font-[400] leading-[24px] -tracking-[3%] my-[20px]"
            onClick={handleBlogViewMore}
          >
            View more
          </button>
        )}
        {/* Product Guides and Updates */}

        <h1 className="text-primary text-[24px] font-[400] leading-[30px] px-[20px] lg:px-0 -tracking-[3%] mt-[50px] mb-[0px]">
          Product Guides and Updates
        </h1>
        <div className="flex flex-col md:flex-row justify-center items-center flex-wrap gap-5">
          {ProductData.slice(0, displayProductCount).map((product) => (
            <BlogBox
              key={product.id}
              id={product.id}
              title={product.title}
              image={product.image}
              date={product.date}
              info={product.info}
              scrollToTop={scrollToTop}
            />
          ))}
        </div>
        {displayProductCount < ProductData.length && (
          <button
            className="text-basegreen text-center underline underline-offset-4 text-[16px] font-[400] leading-[24px] -tracking-[3%] mt-[20px] mb-[40px]"
            onClick={handleProductViewMore}
          >
            View more
          </button>
        )}
      </div>
    </div>
  );
};

export default Blog;

import {useState} from 'react'

const useDisplayBlog = () => {
    const [displayBlogCount, setDisplayBlogCount] = useState(6);
    const handleBlogViewMore = () => {
        setDisplayBlogCount(displayBlogCount + 3); 
      };
  return {displayBlogCount, handleBlogViewMore};
}

export default useDisplayBlog
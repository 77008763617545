import React from 'react'

const StepsBox = ({title, description}) => {
  return (
    <div className='flex flex-col items-start gap-5' data-aos="fade-up">
<h2 className='text-basegreen font-[400] text-[20px] leading-[25px] -tracking-[3%]'>{title}</h2>
<p className='text-secondary font-[400] text-[16px] leading-[24px] -tracking-[3%]'>{description}</p>
    </div>
  )
}

export default StepsBox
import { configureStore } from "@reduxjs/toolkit";
import slice from "./slice";
// import { combineReducers } from 'redux';
// import { persistStore, persistReducer } from "redux-persist";
// import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//     key: 'root',
//     storage,
//   };
  // const rootReducer = combineReducers({
  //   slice: slice.reducer,
  // });
  
  // const persistedReducer = persistReducer(persistConfig, rootReducer);
  
  // export const store = configureStore({
  //   reducer: persistedReducer,
  // });
  
  // export const persistor = persistStore(store);
const store = configureStore({
    reducer: {
        slice: slice.reducer
    }
})



export default store
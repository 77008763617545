import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const createEventUrl = "https://backend-2hjl.onrender.com/api/event/create";
const allEventsUrl = "https://backend-2hjl.onrender.com/api/event/events"
const deleteUrl = "https://backend-2hjl.onrender.com/api/event/delete"
const editUrl = "https://backend-2hjl.onrender.com/api/event/update/:id"
const registerUrl = "https://backend-2hjl.onrender.com/api/event"

export const createEvent = createAsyncThunk(
  "event/create",
  async (pushEvent, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${createEventUrl}`, pushEvent);
      console.log(response)
      return response.data;
      
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
        return rejectWithValue("No response received from server");
      } else {
        console.error("Error setting up request:", error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);
export const registerEvent = createAsyncThunk(
  "event/register",
  async ({ id, ...registerData }, { rejectWithValue }) => {
    try {
      const url = `${registerUrl}/${id}/register`
      const response = await axios.post(url, registerData);
      console.log(response)
      return response.data;
      
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
        return rejectWithValue("No response received from server");
      } else {
        console.error("Error setting up request:", error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);
export const editEvent = createAsyncThunk(
  "event/edit",
  async (id, { getState, rejectWithValue }) => {
    const state = getState()
    const edit_event = state.eventEdit
    try {
      const response = await axios.put(`${editUrl}/${id}`, edit_event);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
        return rejectWithValue("No response received from server");
      } else {
        console.error("Error setting up request:", error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteEvent = createAsyncThunk(
  "event/delete",
  async (id, { getState, rejectWithValue }) => {
    const state = getState()
    const del_event = state.eventDelete
    try {
      const response = await axios.delete(`${deleteUrl}/${id}`, del_event);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
        return rejectWithValue("No response received from server");
      } else {
        console.error("Error setting up request:", error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);
export const fetchEvents = createAsyncThunk(
  "events/fetch",
  async () => {
    const response = await fetch(`${allEventsUrl}`, {
      
      
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log(data)

    return data
  }
);

const slice = createSlice({
  name: "slice",
  initialState: {
    AllEvents: [],
    createEventStatus: "idle",
    fetchEventStatus: "idle",
    editEventStatus: "idle",
    deleteEventStatus: "idle",
    registerEventStatus: "idle",
    eventRegister: {},
    eventDelete: {},
    eventEdit: {},
    error: ""
  },
  reducers: {
    setEvent: (state, action) => {
      console.log(action);
      state.AllEvents = action.payload;
    },
    setDelEvent: (state, action) => {
      state.eventDelete = action.payload
    },
    setEditEvent: (state, action) => {
      state.editEvent =  action.payload
    },
    setRegisterEvent: (state, action) => {
      state.eventRegister = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.pending, (state) => {
        state.createEventStatus = "loading";
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.createEventStatus = "success"
      } )
      .addCase(createEvent.rejected, (state) => {
        state.createEventStatus = "failed"
      })
      .addCase(fetchEvents.pending, (state) => {
        state.fetchEventStatus = "loading";
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.fetchEventStatus = "success"
        state.AllEvents = action.payload
      } )
      .addCase(fetchEvents.rejected, (state) => {
        state.fetchEventStatus = "failed"
      })
      .addCase(editEvent.pending, (state) => {
        state.editEventStatus = "loading";
      })
      .addCase(editEvent.fulfilled, (state, action) => {
        state.editEventStatus = "success"
      } )
      .addCase(editEvent.rejected, (state) => {
        state.editEventStatus = "failed"
      })
      .addCase(deleteEvent.pending, (state) => {
        state.deleteEventStatus = "loading";
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.deleteEventStatus = "success"
      } )
      .addCase(deleteEvent.rejected, (state) => {
        state.deleteEventStatus = "failed"
      })
      .addCase(registerEvent.pending, (state) => {
        state.registerEventStatus = "loading";
      })
      .addCase(registerEvent.fulfilled, (state, action) => {
        state.registerEventStatus = "success"
      } )
      .addCase(registerEvent.rejected, (state) => {
        state.registerEventStatus = "failed"
      });
  },
});

export const sliceAction = slice.actions;

export default slice;

import { useNavigate } from "react-router-dom";
import useScrollTop from './useScrollTop'

const useBlogClick = (id) => {
    let {scrollToTop} = useScrollTop();
    const navigate = useNavigate();

    const handleBlogClick = () => {
      navigate(`/blog/${id}`);
      scrollToTop();
    };
  return {handleBlogClick};
}

export default useBlogClick
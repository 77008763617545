import { useParams } from "react-router-dom";
import { ProductData } from "../utils/BlogData";

const useProductFind = () => {
  const { id } = useParams();
  const product = ProductData.find((product) => product.id === id);
  let productFormatInfo = "";
  if (product && product.info) {
    productFormatInfo = product.info
      .replace(/\n\n/g, "<br /><br />")
      .replace(/\n/g, "<br />");
  }
  return { product, productFormatInfo };
};

export default useProductFind;

import React from "react";
import delivery from "../assets/images/delivery.png";
import { Teamdata } from "../utils/Teamdata";

const About = () => {
  return (
    <div className="mb-10 max-w-full">
      {/* hero */}
      <div className="bg-hero-image bg-neutral">
        <div className=" h-[90vh] flex md:pl-28 ">
          <div
            className="flex flex-col items-start justify-center md:w-[50%] gap-4  px-4 "
            data-aos="fade-right"
          >
            <h1 className="md:text-[46px] text-[28px] pt-20 text-primary tracking-tight md:leading-[57px] sm:leading-[35px] font-normal">
              We're on a mission to transform e-commerce logistics in Nigeria.{" "}
            </h1>
          </div>
        </div>
      </div>
      {/* content  */}
      <div className="w-[80%] mx-auto flex flex-col md:flex-row gap-4 mt-20">
        <img src={delivery} alt="delivery-img" className="rounded-lg"  data-aos='fade-right'/>
        <div className="flex flex-col gap-4 text-primary text-[16px]">
          <p data-aos='fade-up'>
            At RideFraser, we believe that every business deserves access to
            reliable and affordable logistics solutions. That's why we created
            an all-in-one platform to simplify shipping, empower merchants, and
            delight customers.
          </p>

          <p data-aos='fade-up'>
            We understand the unique challenges faced by Nigerian businesses,
            and we're committed to providing innovative solutions that drive
            growth and success.
          </p>

          <p data-aos='fade-up'>
            Our team is passionate about creating a seamless logistics
            experience that makes it easy for businesses to thrive in the
            digital age.
          </p>
        </div>
      </div>
      {/* team  */}
      <div className="w-[80%] text-center mx-auto">
        <h3 className="text-[32px]  mt-16 text-primary mb-4" data-aos='fade-up'>The team</h3>
        <p className="text-[16px] md:w-[60%] w-[95%]  mx-auto text-secondary" data-aos='fade-up'>
          Meet the passionate team behind RideFraser. We're a diverse group of
          problem solvers, innovators, and logistics enthusiasts who are
          dedicated to making a difference in the Nigerian e-commerce landscape.
        </p>
        <div className="grid md:grid-cols-3 md:gap-12 gap-8 grid-cols-2 mt-12" data-aos='flip-right'>
          {Teamdata.map((team, index) => (
            <div key={index} className="flex flex-col gap-2" data-aos='flip-right'>
              <img src={team.img} alt={team.name} className="rounded-lg" />
              <p className="text-primary text-left md:text-[20px] tracking-tight text-[16px]">{team.name}</p>
              <p className="text-secondary text-left text-[14px]">{team.role}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;

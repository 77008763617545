import Amen from '../assets/images/Amen.png'
import Felix from '../assets/images/Felix.png'
import Firdaws from '../assets/images/Firdaws.png'
import Mo from '../assets/images/Mo.png'
import Marv from '../assets/images/Marv.png'
import Marquis from '../assets/images/Marquis.png'
import Peter from '../assets/images/Peter.png'
import Fash from '../assets/images/Fashola.png'
import Fisayomi from '../assets/images/Fisayomi.png'

export const Teamdata = [
    {
        img: Firdaws,
        name: 'Firdaws Lamidi',
        role: 'Engineering'
    },
    {
        img: Mo,
        name: 'Motunrayo Megbodofo',
        role: 'Product'
    },
    {
        img: Felix,
        name: 'Felix Bissong',
        role: 'Operations'
    },
    {
        img: Fash,
        name: 'Fashola Olatunji',
        role: 'Sales'
    },
    {
        img: Marquis,
        name: 'Marquis Abah',
        role: 'Engineering'
    },
    {
        img: Fisayomi,
        name: 'Fisayomi Aiyetogbon',
        role: 'Engineering'
    },
    {
        img: Peter,
        name: 'Peter Awotola',
        role: 'Engineering'
    },
    {
        img: Marv,
        name: 'Marvelous-Grace Oyeyemi',
        role: 'Marketing'
    },
    {
        img: Amen,
        name: 'Amen Olabode',
        role: 'Product'
    },
]
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NavbarModal from "./NavbarModal";
import Logo from '../assets/images/Logo.png'
import Menu from '../assets/images/Menu.svg'
import Close from '../assets/images/x.svg'
import Globe from '../assets/images/globe.svg'

const Navbar = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname.startsWith(path);
  const [showNavbarModal, setShowNavbarModal] = useState(false);
  const handleOpenNavbarModal = () => {
    setShowNavbarModal(!showNavbarModal);
  };
  const handleLinkClick = () => {
    setShowNavbarModal(false);
  };
  return (
    <div className="fixed z-50 top-0 left-0 bg-white flex justify-between items-center h-auto gap-[10px] px-[12px] py-[16px] border-b-[1px] border-neutral shadow-sm w-full">
      <div className="flex items-center">
        {/* Ridefraser Logo */}
        <Link to="/">
          <img
            src={Logo}
            alt="Ride Fraser Logo"
            className="w-[150px] h-[33px] mx-[5px] md:mx-[30px]"
          />
        </Link>
        <div className="hidden md:flex items-center gap-10 ml-[50px]">
          {/* About Navigation Link */}
          <Link
            to="/about"
            className={
              isActive("/about")
                ? "text-basegreen"
                : "text-secondary  hover:text-basegreen"
            }
          >
            <p className="cursor-pointer text-[16px] font-[400] leading-[18px] -tracking-[3%]">
              About
            </p>
          </Link>
          {/* Blog Navigation Link */}
          <Link
            to="/blog"
            className={
              isActive("/blog")
                ? "text-basegreen"
                : "text-secondary  hover:text-basegreen"
            }
          >
            <p className="cursor-pointer text-[16px] font-[400] leading-[18px] -tracking-[3%]">
              Blog
            </p>
          </Link>
          {/* API Documentation Navigation Link */}
            <a href="https://fraser.stoplight.io/docs/central/cbb186dc155a8-merchant-management" target="_blank" rel="noreferrer" className="cursor-pointer text-[16px] font-[400] leading-[18px] -tracking-[3%] text-secondary  hover:text-basegreen">
              API Documentation
            </a>
        </div>
      </div>
      {/* Join our Community Navigation Link */}
      <Link to="/community">
        <div className="hidden md:flex items-center gap-[2px] lg:gap-2 mr-[3px] lg:mr-[40px]">
          <img
            src={Globe}
            alt="Globe icon"
            className="w-[24px] h-[24px]"
          />

          <p className="cursor-pointer text-[16px] font-[400] leading-[18px] text-primary -tracking-[3%]">
            Join our community
          </p>
        </div>
      </Link>
      {/* Navbar Menu for Mobile View */}
      <div className="block mx-[5px] md:hidden">
        <button
          className="flex items-center cursor-pointer"
          onClick={handleOpenNavbarModal}
        >
          <img
            src={
              showNavbarModal
                ? Close
                : Menu
            }
            alt="Navbar icon"
            className="w-[40px] h-[40px]"
          />
        </button>
      </div>
      {/* Navbar Modal for Mobile View */}
      {showNavbarModal && <NavbarModal handleLinkClick={handleLinkClick} />}
    </div>
  );
};

export default Navbar;

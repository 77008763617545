import React from 'react'

const FeaturesBox = ({icon, title, description}) => {
  return (
    <div className='flex flex-col items-start justify-center lg:justify-normal  gap-8 w-[90%] lg:w-[310px] h-[320px] px-[20px] py-[30px] bg-[#FAFAFA] shadow-sm rounded-[12px]' data-aos="zoom-out">
        <img src={icon} alt={`${icon} icon`} className='w-[24px] h-[24px]'/>
        <h3 className='text-primary font-[400] text-[20px] leading-[30px] -tracking-[3%]'>{title}</h3>
        <p className='text-secondary font-[400] text-[16px] leading-[24px] -tracking-[3%]'>{description}</p>
    </div>
  )
}

export default FeaturesBox
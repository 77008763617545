import React from 'react'
import Header from '../components/Header'
import Features from '../components/Features'
import SetUp from '../components/SetUp'
import ConnectStore from '../components/ConnectStore'
import FAQ from '../components/FAQ'


const Home = () => {
  return (
    <div className='max-w-[100%]'>
    {/*Ridefraser Home page */}
    <Header/>
    <Features/>
    <SetUp/>
    <ConnectStore/>
    <FAQ/>
    </div>
  )
}

export default Home